<template>
  <div class="warn-log-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <!-- <WarnItem v-for="item of listData" :key="item.id" :data="item"></WarnItem> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <WarnItem v-for="item of listData" :key="item.id" :data="item"></WarnItem>
      </van-list>
    </div>
    <NoData v-if="!hasData" type="AlarmEvent" />
  </div>
</template>

<script>
import Vue from 'vue'

import moment from 'moment'
import { mapActions } from 'vuex'
import NoData from '@/components/NoData'
import WarnItem from './components/WarnItem'
import { getWarnLogDetail } from '@/api/deviceDetail'

import { List, Cell } from 'vant'
Vue.use(List).use(Cell)

export default {
  name: 'WarnLog',
  components: {
    WarnItem,
    NoData,
  },
  data() {
    return {
      deviceId: '',
      listData: [],
      hasData: true,
      loading: false,
      finished: false,
      params: {
        page: 0,
        size: 10,
        sort: 'id,desc',
        deviceId: '',
      },
    }
  },
  mounted() {
    this.params.deviceId = this.$route.query.id
    this.getWarnLogDetailRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    /**
     * 获取设备详情
     */
    getWarnLogDetailRequest() {
      // getWarnLogDetail(this.params)
      //   .then((res) => {
      //     if (res.length > 0) {
      //       this.hasData = true
      //       this.listData = res
      //       this.listData.map((item) => {
      //         item.alarmTime = moment(item.alarmTime).format(
      //           'YYYY-MM-DD HH:mm:ss'
      //         )
      //         return item
      //       })
      //     } else {
      //       this.hasData = false
      //     }
      //   })
      //   .catch(() => {})
      //   .finally(() => {
      //     this.setLoading(false)
      //   })

      getWarnLogDetail(this.params).then((res) => {
        const { content, totalElements } = res
        if (content.length > 0) {
          content.forEach((item) => {
            this.listData.push(item)
            this.listData.map((item) => {
              item.alarmTime = moment(item.alarmTime).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              return item
            })
          })
          this.hasData = true
        } else {
          this.hasData = false
        }
        this.loading = false
        if (this.listData.length >= totalElements) {
          this.finished = true
          this.loading = true

        } else {
          this.params.page = this.params.page + 1
        }
      })
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getWarnLogDetailRequest()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.warn-log-wrapper {
  width: 100%;
  height: 100%;
  .has-data-wrapper {
    width: 100%;
    height: 100%;
  }
}
</style>
