
export const deviceTypeList = [
  { label: '未定义', value: '0' },
  { label: '注塑机', value: '1' },
  { label: '模温机', value: '2' },
  { label: '机器人', value: '3' },
  { label: '冲压机', value: '4' },
  { label: '冷水机', value: '5' },
  { label: '空压机', value: '6' },
  { label: '循环水系统', value: '7' },
  { label: '组装线', value: '8' }]
export const deviceTypeKey = {
  0: '未定义',
  1: '注塑机',
  2: '模温机',
  3: '机器人',
  4: '冲压机',
  5: '冷水机',
  6: '空压机',
  7: '循环水系统',
  8: '组装线',
}

export const maintainTypeList = [
  { label: '一级保养', value: '1' },
  { label: '二级保养', value: '2' },
  { label: '大修计划', value: '3' },
  { label: '其他养护', value: '4' }
]
export const maintainKey = {
  '1': '一级保养',
  '2': '二级保养',
  '3': '大修计划',
  '4': '其他养护',
}


export const statusTypeList = [
  { label: '已作废', value: '-1' },
  { label: '待派单', value: '1' },
  { label: '已派单', value: '2' },
  { label: '已接单', value: '3' },
  { label: '已完成', value: '4' }
]

export const faultLevelList = [
  { label: '一般故障', value: '1' },
  { label: '严重故障', value: '2' },
  { label: '紧急故障', value: '3' },
  { label: '其他故障', value: '4' },
]


export const faultStatusKey = {
  '-1': '作废',
  0: '待派单',
  1: '已派单',
  2: '已接单 ',
  3: '已完成',
  4: '验收通过',
  5: '验收不通过',

}


