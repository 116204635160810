<template>
  <div class="data-monitor-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <div class="basic-info-wrapper">
        <div class="title">
          <h2>基础信息</h2>
          <!-- <div class="switch-btn">
            <span>仅看关键数据</span>
            <van-switch v-model="checked" active-color="#07c160" inactive-color="#ee0a24" />
          </div> -->
        </div>
        <div class="list">
          <ListItem :title="item.dataName" v-for="(item, index) of listData" :key="item.itemCode" :index="index" @handleSelectItem="handleSelectItem(item, index)">
            <div class="list-content" slot="value">
              <div class="value">{{ item.val }}</div>
              <div class="uni">{{ item.unit }}</div>
            </div>
          </ListItem>
        </div>
      </div>
      <div class="trend-wrapper">
        <div class="title">
          <h2>近24小时趋势</h2>
        </div>
        <div class="table">
          <div class="table-title">
            <h3>{{ listData[currentIndex].dataName }}</h3>
            <span>{{ listData[currentIndex].unit }}</span>
          </div>
          <div class="table-content" ref="lineEcharts"></div>
        </div>
      </div>
    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NoData from '@/components/NoData'
import Vue from 'vue'
import { Switch } from 'vant'
import 'vant/lib/switch/style'
import ListItem from './components/ListItem'
import {
  getDataMonitorDetail,
  getDataMonitorHistoryDetail,
} from '@/api/deviceDetail'

let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')

Vue.use(Switch)
export default {
  name: 'DataMonitor',
  components: {
    ListItem,
    NoData,
  },
  data() {
    return {
      checked: true,
      deviceId: '',
      listData: [
        {
          itemName: '',
          unit: '',
        },
      ],
      currentIndex: 0,
      hasData: true,
      timeArray: [],
      valArray: [],
    }
  },
  mounted() {
    this.deviceId = this.$route.query.id
    this.getDataMonitorRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    /**
     * 选择
     */
    handleSelectItem(item, index) {
      this.currentIndex = index
      // this.checked = this.listData[this.currentIndex].isCrux == 1 ? true : false
      this.getDataMonitorHistoryDetail(item.dataCode)
    },
    /**
     * 绘制折线图
     */
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(this.$refs.lineEcharts)

      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: 100,
          top: 20,
        },
        xAxis: {
          name: '(h)',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#666666',
            fontSize: 12,
          },
          data: this.timeArray,
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#666666',
            fontSize: 12,
          },
        },
        series: [
          {
            name: '销量',
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              color: '#4A8CFF',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#4A8CFF', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFFFFF', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            markPoint: {
              data: [{ type: 'max', name: '最大值' }],
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
            },
            data: this.valArray,
          },
        ],
      }

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    /**
     * 获取数据监控
     */
    getDataMonitorRequest() {
      this.setLoading(true)
      getDataMonitorDetail(this.deviceId)
        .then((res) => {
          if (res.length > 0) {
            this.hasData = true
            this.listData = res
            this.drawLine()
            // this.checked = this.listData[this.currentIndex].isCrux == 1 ? true : false
            if(this.listData.length!='0'){
              this.handleSelectItem(res[0],0)
            }
          } else {
            this.hasData = false
          }
        })
        .catch(() => {})
        .finally(() => {
          this.setLoading(false)
        })
    },
    getDataMonitorHistoryDetail(clctAddr) {
      this.timeArray=[]
      this.valArray=[]
      let param = {
        clctAddr,
      }
      getDataMonitorHistoryDetail(param).then((res) => {
        res.map((item) => {
          this.timeArray.push(item.timestr)
          this.valArray.push(item.sval)
        })
        this.drawLine()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.data-monitor-wrapper {
  width: 100%;
  height: 100%;
  .has-data-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .basic-info-wrapper {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
      .switch-btn {
        display: flex;
        align-items: center;
        span {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        /deep/.van-switch {
          width: 2.8rem;
          height: 1.4rem;
          .van-switch__node {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .list {
      height: 0;
      flex: 1;
      background-color: white;
      overflow-y: auto;
      .list-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding-left: 1rem;
        .value {
          font-size: 0.7rem;
          color: #333333;
          &.red {
            color: #ff3333;
          }
        }
        .uni {
          font-size: 0.7rem;
          color: #999999;
        }
      }
    }
  }
  .trend-wrapper {
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
    }
    .table {
      background-color: white;
      .table-title {
        @include wh(100%, 2.2rem);
        box-sizing: border-box;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        h3 {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        span {
          font-size: 0.6rem;
          color: #999999;
        }
      }
      .table-content {
        @include wh(100%, 13rem);
      }
    }
  }
}
</style>
