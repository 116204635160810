<template>
  <div class="info-list-wrapper">
    <div class="title-box">
      <Title :title="data.title" />
    </div>
    <div class="list-box">
      <ListItem
        v-for="item of data.list"
        :title="item.name"
        :key="item.key"
      >
        <div class="list-content" slot="value">
          <div class="value">{{item.value}}</div>
        </div>
      </ListItem>
    </div>

  </div>
</template>

<script>
  import Title from './Title'
  import ListItem from './ListItem'
  export default {
    name: "InfoPanel",
    components: {
      Title,
      ListItem
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/mixin";
  .info-list-wrapper{
    background-color: white;
    margin-bottom: 0.6rem;
    .title-box{
      @include wh(100%, 2rem);
      box-sizing: border-box;
      padding: 0 1rem;
      border-bottom: 1px solid #F1F1F1;
    }
    .list-box{
      /deep/.left{
        .title {
          color: #999;
        }
      }
      /deep/.list-item-wrapper{
        height: 2rem;
      }
      .value{
        font-size: 0.7rem;
        color: #333;
      }
    }

  }
</style>
