<template>
  <div class="log-item-box">
    <div class="icon-box">
      <i
        class="iconfont"
        :class="data.icon"
        :style="`color: ${data.title === '添加' ? '#999' : ''}`"
      ></i>
    </div>
    <div class="text-box" @click="handleRepairClick(data.id)">
      <div class="top">
        <span>{{ data.date }}</span>
      </div>
      <div class="bottom">
        <div class="info">
          <div class="infoDetail">
            <span>设备名称：</span><span>{{ data.deviceName }}</span>
          </div>
          <div class="infoDetail">
            <span>设备编号：</span><span>{{ data.deviceNo }}</span>
          </div>
          <div class="infoDetail">
            <span>维修单号：</span><span>{{ data.repairNo }}</span>
          </div>
          <div class="infoDetail">
            <span>工单状态：</span
            ><span>{{ faultStatusKey[data.faultStatus] }}</span>
          </div>
        </div>
        <i class="iconfont iconyou"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { faultStatusKey } from '@/config/statusConfig'
export default {
  name: 'LogItem',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      faultStatusKey,
    }
  },
  methods: {
    handleRepairClick(id) {
      this.$router.push({
        path: '/deviceRepair/status',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.log-item-box {
  @include wh(100%, auto);
  box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
  .icon-box {
    @include wh(2.5rem, auto);
    text-align: center;
    box-sizing: border-box;
    margin-right: 0.2rem;
    i {
      color: #4a8cff;
      font-size: 2rem;
    }
  }
  .text-box {
    flex: 1;
    box-sizing: border-box;
    .top {
      @include wh(100%, 50%);
      display: flex;
      align-items: center;
      margin-bottom: 0.4rem;
      h3 {
        margin-right: 1rem;
        font-size: 0.76rem;
        font-weight: bold;
        color: black;
      }
      span {
        font-size: 0.65rem;
        color: #4a8cff;
      }
    }
    .bottom {
      @include wh(100%, 50%);
      display: flex;
      flex-direction: row;
      align-items: center;
      .info {
        width: 0;
        flex: 1;
        margin-right: 0.2rem;

        .infoDetail {
          margin-bottom: 0.2rem;
          display: flex;
          flex-direction: row;
          // align-items: center;

          span {
            font-size: 0.6rem;
          }
          span:nth-child(2) {
            font-size: 0.5rem;
            color: #999999;
            display: block;
            width: 0;
            flex: 1;
            word-wrap: break-word;
          }
        }
      }

      i {
        color: #999999;
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
  }
}
</style>
