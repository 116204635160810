import axios from '@/config/axios'

// 设备信息
export const getDeviceDetail = (id) => axios({
  method: 'get',
  url: `/mobile/device/${id}`
})


// 数据监控
export const getDataMonitorDetail = (deviceId) => axios({
  method: 'get',
  url: `/mobile/deviceMonitor/${deviceId}`
})

// 数据监控
export const getDataMonitorHistoryDetail = (params) => axios({
  method: 'get',
  url: `/mobile/deviceMonitor/history`,
  params
})

// 告警记录
export const getWarnLogDetail = (params) => axios({
  method: 'get',
  url: `/mobile/deviceDataWarnLog`,
  params
})

// 设备日志
export const getDeviceLogDetail = (params) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/deviceFaultList`,
  params
})


// 设备主从关系
export const deviceMainFollow = (deviceId) => axios({
  method: 'get',
  url: `/mobile/mainFollow/${deviceId}`
})

// 设备告警
export const deviceDataWarnLog = () => axios({
  method: 'get',
  url: `/mobile/deviceDataWarnLog`
})
