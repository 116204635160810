<template>
  <div class="warn-box">
    <div class="list-item-wrapper">
      <div
        :class="`item-wrapper ${borderBottom ? 'border-line' : ''}`"
        @click="handleListItemClick"
      >
        <div class="left">
          <div class="icon">
            <i class="iconfont iconsanwei"></i>
          </div>
          <div class="title">
            {{ data.dataName }}
            <!-- <span>（{{ data.unit }}）</span> -->
          </div>
        </div>
        <div class="right">
          <i class="iconfont iconshijian"></i>
          <span>{{
            data.warnTime && moment(data.warnTime).format('YYYY-MM-DD')
          }}</span>
        </div>
      </div>
    </div>
    <div class="list-item-wrapper">
      <div
        :class="`item-wrapper ${borderBottom ? 'border-line' : ''}`"
        @click="handleListItemClick"
      >
        <div class="left" style="width: 100%">
          <!--<div class="title">事件信息：<span class="red">高于</span>上限值<span>（<span class="red">3000</span> > 2000）</span></div>-->
          <div class="title">事件信息：{{ data.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WarnItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
    itemStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    /**
     * 点击事件
     */
    moment,
    handleListItemClick() {
      this.$emit('handleListItemClick')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';

.warn-box {
  background-color: white;
  margin-top: 1rem;
}
.list-item-wrapper {
  @include wh(100%, 3rem);
  box-sizing: border-box;
  padding-left: 1rem;
  .item-wrapper {
    @include wh(100%, 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border-line {
      box-sizing: border-box;
      border-bottom: 1px solid #f1f1f1;
    }
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 0.5rem;
        i {
          color: #4a8cff;
        }
      }
      .title {
        font-size: 0.7rem;
        color: black;
        margin-top: 0.2rem;
        span {
          color: #999999;
        }
        span.red {
          color: #ff4949;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 0.5rem;
      i {
        color: #cdcdcd;
        margin-right: 0.3rem;
      }
      span {
        font-size: 0.7rem;
        color: black;
        margin-top: 0.2rem;
      }
    }
  }
}
</style>
