<template>
  <div class="device-log-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <van-steps direction="vertical" :active="LogData.length - 1">
        <van-step v-for="item of LogData" :key="item.title">
          <LogItem :data="item" />
        </van-step>
      </van-steps>
    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import NoData from '@/components/NoData'
import Vue from 'vue'
import { Step, Steps } from 'vant'
import LogItem from './components/LogItem'
import { getDeviceLogDetail } from '@/api/deviceDetail'
import 'vant/lib/step/style'
import 'vant/lib/steps/style'

Vue.use(Step).use(Steps)
export default {
  name: 'DeviceLog',
  components: {
    LogItem,
    NoData,
  },
  data() {
    return {
      LogData: [
        {
          icon: 'iconGIS-TL_weixiudian-',
          title: '维修',
          date: '2018-04-15  17:01:12',
          user: '',
          number: 'WX2018041517011201',
        },
        {
          icon: 'iconweixiu2',
          title: '保养',
          date: '2018-04-15  17:01:12',
          user: '',
          number: 'WX2018041517011201',
        },
        {
          icon: 'iconguzhangzhuangtai',
          title: '故障',
          date: '2018-04-15  17:01:12',
          user: '',
          number: 'WX2018041517011201',
        },
        {
          icon: 'icontianjia',
          title: '添加',
          date: '2018-04-15  17:01:12',
          user: '李四',
          number: 'WX2018041517011201',
        },
      ],
      iconData: {
        维修: 'iconGIS-TL_weixiudian-',
        保养: 'iconweixiu2',
        启用: 'iconweixiu2',
        故障: 'iconguzhangzhuangtai',
        添加: 'icontianjia',
      },
      deviceId: '',
      hasData: false,
    }
  },
  mounted() {
    this.deviceId = this.$route.query.id
    this.getDeviceLogDetailRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    /**
     * 获取设备详情
     */
    getDeviceLogDetailRequest() {
      this.setLoading(true)
      let params = {
        deviceId: this.deviceId,
      }
      getDeviceLogDetail(params)
        .then((res) => {
          if (res.details.content.length > 0) {
            this.hasData = true
            this.LogData = []
            res.details.content.forEach((item) => {
              this.LogData.push({
                icon: this.iconData['维修'],
                repairNo: item.repairNo,
                faultStatus: item.faultStatus,
                deviceName: item.deviceName,
                deviceNo: item.deviceNo,
                id: item.id,
                date: moment(item.logTime).format('YYYY-MM-DD HH:mm:ss'),
              })
            })
          } else {
            this.hasData = false
          }
        })
        .catch(() => {})
        .finally(() => {
          this.setLoading(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-log-wrapper {
  @include wh(100%, 100%);
  background-color: white;
  box-sizing: border-box;
  padding-right: 0.2rem;
  .has-data-wrapper {
    @include wh(100%, 100%);
  }
  /deep/.van-steps {
    .van-hairline {
      &::after {
        border: 0;
      }
    }
    .van-step__circle {
      width: 0.6rem;
      height: 0.6rem;
      background-color: #4a8cff;
    }
    .van-step__line {
      background-color: #f1f1f1;
    }
    .van-icon-checked {
      width: 0.6rem;
      height: 0.6rem;
      background-color: #f1f1f1;
      border-radius: 50%;
      &::before {
        content: '';
      }
    }
  }
}
</style>
