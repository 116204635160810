<template>
  <div class="device-info-wrapper">
    <div class="device-info-header" v-if="hasData">
      <div class="icon">
        <img :src="imgUrl + deviceInfo.photoUrl" alt="" />
      </div>
      <div class="text">
        <div class="title">{{ deviceInfo.deviceName }}</div>
        <div class="number">设备编号：{{ deviceInfo.deviceNo }}</div>
        <div class="date">启用日期：{{ deviceInfo.deviceCreateDate }}</div>
      </div>
    </div>
    <div class="basic-info-wrapper" v-if="hasData">
      <div
        class="info-list"
        v-for="(item, index) in followDevices"
        :key="index"
        @click="routerDetail(item)"
      >
        <div class="list-name">
          <span>{{ item.deviceName }}</span>
        </div>
        <div class="list-no">
          <span>{{ item.deviceNo }}</span>
        </div>
      </div>
    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { deviceMainFollow } from '@/api/deviceDetail'
import { getEnums } from '@/api/deviceMonitor'
import { imgUrl } from '@/config/env'
import NoData from '@/components/NoData'

export default {
  name: 'DeviceInfo',
  components: {
    NoData,
  },
  data() {
    return {
      imgUrl,
      deviceId: null,
      deviceInfo: {},
      followDevices: [],
      hasData: true,
    }
  },
  mounted() {
    this.deviceId = this.$route.query.id
    this.deviceMainFollow()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    deviceMainFollow() {
      deviceMainFollow(this.deviceId)
        .then((res) => {
          this.hasData = true
          this.deviceInfo = res
          this.followDevices = res.followDevices
        })
        .catch((err) => {
          this.hasData = false
        })
    },
    routerDetail(item) {
      this.$parent.active = 0
      this.$router.push({
        path: '/deviceDetail',
        query: {
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-info-wrapper {
  @include wh(100%, 100%);
  .device-info-header {
    @include wh(100%, 6.5rem);
    background: linear-gradient(
      90deg,
      rgba(79, 147, 255, 1) 0%,
      rgba(58, 187, 253, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1rem;
    .icon {
      @include wh(4rem, 4rem);
      background-color: white;
      border-radius: 0.3rem;
      overflow: hidden;
      @include CC;
      img {
        @include wh(100%, 100%);
      }
    }
    .text {
      flex: 1;
      box-sizing: border-box;
      padding-left: 0.7rem;
      line-height: 1.2rem;
      .title {
        font-size: 0.75rem;
        color: white;
      }
      .number {
        font-size: 0.65rem;
        color: white;
      }
      .date {
        font-size: 0.65rem;
        color: white;
      }
      // .down-icon {
      //   box-sizing: border-box;
      //   padding-left: 3rem;
      //   i {
      //     font-size: 0.7rem;
      //     color: white;
      //     width: 0.7rem;
      //     height: 1rem;
      //     display: block;
      //     &.transform180Animation {
      //       animation: transform180Animation 0.5s forwards;
      //     }
      //     &.transform0Animation {
      //       animation: transform0Animation 0.5s forwards;
      //     }
      //   }
      // }
    }
  }
  .basic-info-wrapper {
    margin-bottom: 0.6rem;
    height: 12rem;
    overflow: hidden;
    height: 100%;
    background: #fff;

    .info-list {
      padding: 0.8rem 1rem;
      border-bottom: 1px solid #e5e5e5;
      .list-name {
        position: relative;
        padding-left: 1rem;
        span {
          font-size: 0.9rem;
          color: #333;
          font-weight: bold;
        }
      }
      .list-name::before {
        content: '';
        width: 2px;
        height: 60%;
        position: absolute;
        top: 20%;
        left: 0;
        background: #3663ff;
      }
      .list-no {
        color: #999;
        font-size: 0.6rem;
        font-weight: bold;
        padding: 0.5rem 0 0 1rem;
      }
    }
    //   &.expandAnimation {
    //     animation: expandAnimation 0.5s forwards;
    //   }
    //   &.shrinkAnimation {
    //     animation: shrinkAnimation 0.5s forwards;
    //   }
    // }
    // .link-info-wrapper {
    //   background-color: white;
    //   margin-bottom: 1rem;
    //   /deep/.list-item-wrapper {
    //     height: 2.7rem;
    //     .left {
    //       width: 50%;
    //       .icon {
    //         i {
    //           color: #4a8cff;
    //           font-size: 1.1rem;
    //         }
    //       }
    //       .title {
    //         font-size: 0.8rem;
    //         margin-top: 0.2rem;
    //       }
    //     }
    //   }
    //   .status {
    //     span {
    //       color: #4a8cff;
    //       font-size: 0.75rem;
    //       margin-right: 1rem;
    //     }
    //   }
    //   .iconyou {
    //     font-size: 0.7rem;
    //     color: #999999;
    //   }
    // }
  }
}

// @keyframes transform180Animation {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(-180deg);
//   }
// }

// @keyframes transform0Animation {
//   from {
//     transform: rotate(-180deg);
//   }
//   to {
//     transform: rotate(0deg);
//   }
// }

// @keyframes expandAnimation {
//   from {
//     height: 0;
//   }
//   to {
//     height: 12rem;
//   }
// }

// @keyframes shrinkAnimation {
//   from {
//     height: 12rem;
//   }
//   to {
//     height: 0;
//   }
// }
</style>
